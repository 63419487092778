<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 70px !important;">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">{{data_title}}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-box">
          <list-table :viewParams="viewParams" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/ListTable.vue'
import Headers from '@/components/Headers.vue'
import router from '@/router'

export default {
  name: 'CorpList',
  components: {
    ListTable,
    Headers
  },
  data() {

     const { currentRoute } = router
    return {
      data_title:currentRoute.value.query.data_title==undefined ? '短期業績好調企業一覧': currentRoute.value.query.data_title,
      viewParams: {
        name: 'corp1',
        apiUrl: '/v1/corps1/',
        articleView: 'CorpArticle1',
        articleParamId: 'ID',
        dataType:1,
      }
    }
  }
}
</script>